/* 버튼 이벤트 */
.button-event {
  cursor: pointer !important;
  user-select: none !important;
}
.button-event:hover {
  opacity: 0.8 !important;
}
.button-event:active {
  transform: translateY(1px) !important;
}

/* 버튼 이벤트 - 배경색 */
.button-bg-event {
  cursor: pointer !important;
  user-select: none !important;
}
.button-bg-event:active {
  background-color: rgb(245, 245, 245);
}

/* 버튼 이벤트 - 배경색 반투명 */
.button-bg-opacity-event {
  cursor: pointer !important;
  user-select: none !important;
}
.button-bg-opacity-event:active {
  opacity: 0.5 !important;
}

/* 텍스트 그림자 */
.text-shadow {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}
.text-shadow-xs {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

/* **************************** */

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* **************************** */

/**
 * 퍼블리셔 CSS 수정
 */

.main-menu {
  position: relative !important;
  z-index: 1 !important;
}

.main-menu-bg-color {
  background: #343861;
}

.footer .inner {
  width: auto !important;
  padding: 30px 30px 18px 32px !important;
  display: flex;
}

.copyright-wrapper {
  width: auto !important;
  padding: 0 30px 64px 32px !important;
}

.location .inner {
  width: auto !important;
  height: 64px;
  padding: 0 30px 0 32px !important;
  display: flex;
  align-items: center;
}

.page-title .inner {
  min-width: 100% !important;
  /*width: auto !important;*/
  padding: 0 30px 0 32px !important;
  display: flex;
  align-items: center;
}

.date-picker {
  padding: 0 0 0 0 !important;
  background: none !important;
  background-size: 16px;
  border-bottom: none !important;
}

.btn {
  margin: 0 !important;
}

.date-select-wrapper {
  display: flex;
  justify-content: start !important;
  align-items: center;
}

.content-wrapper {
  margin: 0 !important;
}

.form-control {
  width: unset !important;
}

.title-wrapper {
  margin: 0 0 7px !important;
  display: flex;
  align-items: center;
}

/* **************************** */

/* 퍼블리셔 버튼 */
.pb-button {
  background: #fff;
  border: 1px solid #d4d2f1;
  border-radius: 16px;
  color: #111;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-weight: 600;
  height: 35px;
  padding: 8px 24px;
  cursor: pointer;
  user-select: none;
}
.pb-button:hover {
  opacity: 0.8;
}
.pb-button:active {
  transform: translateY(1px);
}
.pb-button.del {
  color: #fb384b;
}
.pb-button.primary {
  padding: 8px 24px;
  background: #645af1;
  border: none;
  color: #fff;
}
.pb-button.secondary {
  padding: 8px 24px;
  background: #f9f9f9;
  border: 1px solid #e1e1e1;
  color: #111;
}
.pb-button i {
  width: 12px;
  height: 12px;
  margin: 0 8px 0 0;
  display: block;
}
.pb-button .icon-list {
  background: url('./images/icon_list.png') no-repeat;
}
.pb-button .icon-edit {
  background: url('./images/icon_edit.png') no-repeat;
}
.pb-button .icon-add {
  background: url('./images/icon_add.png') no-repeat;
}
.pb-button .icon-del {
  background: url('./images/icon_del.png') no-repeat;
}

/* 날짜 선택 피커 */
.date-picker:focus {
  outline: none;
}
.date-picker::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
.date-picker::-webkit-calendar-picker-indicator:hover {
  opacity: 0.8;
}
.date-picker::-webkit-calendar-picker-indicator:active {
  transform: translateY(1px);
}

/* 폼 그리드 */
.form-grid {
  border: 1px solid #e1e1e1;
  background-color: #e1e1e1;
}

/* 폼 그리드 항목 레이블 */
.form-grid-col-label {
  width: 180px;
  padding: 11px 16px;
  background: #f4f5fb;
  border-right: 1px solid #e1e1e1;
  color: #000;
  font-weight: 600;
  text-align: center;
}

/* 폼 그리드 항목 값 */
.form-grid-col-value {
  padding: 11px 16px;
  text-align: left;
  background-color: white;
}

/* 폼 가로 크기 */
.pb-form-size-tiny {
  width: 50px !important;
}
.pb-form-size-mini {
  width: 100px !important;
}
.pb-form-size-xs {
  width: 150px !important;
}
.pb-form-size-sm {
  width: 200px !important;
}
.pb-form-size-md {
  width: 300px !important;
}
.pb-form-size-lg {
  width: 400px !important;
}
.pb-form-size-xl {
  width: 500px !important;
}
.pb-form-size-half {
  width: 50% !important;
}
.pb-form-size-full {
  width: 100% !important;
}

/* 컬럼 */
.column-box {
  border: 1px solid #e1e1e1;
}
.column-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  background-color: #f4f5fb;
  border-bottom: 1px solid #e1e1e1;
}
.column-header span {
  font-size: 1rem;
  font-weight: 600;
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}
.column-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px 0;
  background-color: #ffffff;
}
.column-content span {
  font-size: 1rem;
  font-weight: 600;
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.div-bg-color {
  background-color: #f4f5fb;
}

.div-menu-bg-color {
  background-color: #343861;
}

/* 컴포넌트 모달 버튼 영역 */
.component-modal-button-area {
  /*position: absolute !important;*/
  /*left: 0 !important;*/
  /*bottom: 0 !important;*/
  width: 100%;
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-top-width: 1px;
  border-color: rgb(243, 244, 246);
  /*z-index: 20 !important;*/
}
.component-modal-button-area > div {
  margin-left: 0.5rem;
}

/* **************************** */

/**
 * Mantine
 */

.mantine-MonthsList-monthsList tr {
  border-bottom: 0 !important;
}
.mantine-MonthsList-monthsList td {
  border-right: 0 !important;
}

.mantine-Modal-header {
  z-index: 20 !important;
}

.mantine-Modal-body {
  position: relative;
  z-index: 10 !important;
}

/* **************************** */

/**
 * AG-Grid
 */

.ag-theme-alpine {
  --ag-border-color: #e1e1e1 !important;
  --ag-header-background-color: #f4f5fb !important;
}

/* **************************** */

/* 업체 로고 */
.company-logo {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAAYCAMAAACCyC6UAAAAM1BMVEVHcEz////////////////////////////////////////////////////////////////P0YphAAAAEHRSTlMAMKBQcBCQwIBA4CCwX/DQTosghAAAAY5JREFUOMvVVFsW3SAIBAUEH0n2v9qqGM3t6QbqhxhklBkwAH1UbRql26INGZENrDAWLFxKQVYgsbFUxuEeGEjNBBUgUI2iIbRKUVNVIgkipK2DYms3BRWVEAYGayOhYKBJVckkVCIKUdVawDGDBL6LUCKKpgr/HpHKXx6L8B8NTmc0wGntu9Ump5Cf56ntpficQdCmXQLJ/LCBfiOC79AH1GWfNs0ddGcBvk+I3xX6Kq/0eqF9y4v+rueNt9Zh6t469coeOFolPu+t1wqRncTY2JXjTQ544cVZdw8UM8OdOJn5tx2+uvHurA1PxxyOyVVJ/YoMpeeUXBy+VkBWdpAekLoqYRDmMcWlCb6o53ZU/VFznE3jnMFZBiiPoEL5HLzkkknJ2FWxRexi3RJ3XWJ4fsSzl+BUhfk9NH3mVZy0m4i/quRVrGXjjp01kN1Eqx9wqpIWz+hZlCnOk2u6dlLy6TyPVnix5s5PUfwiSMex85nRtor2UxXHwOcxiX/hfEeDeUtu+2PqbX4lXT+OPx94I3TOIgEuAAAAAElFTkSuQmCC);
}

/* 업체 로고 */
.company-logo-2 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAAYCAMAAACCyC6UAAAAM1BMVEVHcEz////////////////////////////////////////////////////////////////P0YphAAAAEHRSTlMAMKBQcBCQwIBA4CCwX/DQTosghAAAAY5JREFUOMvVVFsW3SAIBAUEH0n2v9qqGM3t6QbqhxhklBkwAH1UbRql26INGZENrDAWLFxKQVYgsbFUxuEeGEjNBBUgUI2iIbRKUVNVIgkipK2DYms3BRWVEAYGayOhYKBJVckkVCIKUdVawDGDBL6LUCKKpgr/HpHKXx6L8B8NTmc0wGntu9Ump5Cf56ntpficQdCmXQLJ/LCBfiOC79AH1GWfNs0ddGcBvk+I3xX6Kq/0eqF9y4v+rueNt9Zh6t469coeOFolPu+t1wqRncTY2JXjTQ544cVZdw8UM8OdOJn5tx2+uvHurA1PxxyOyVVJ/YoMpeeUXBy+VkBWdpAekLoqYRDmMcWlCb6o53ZU/VFznE3jnMFZBiiPoEL5HLzkkknJ2FWxRexi3RJ3XWJ4fsSzl+BUhfk9NH3mVZy0m4i/quRVrGXjjp01kN1Eqx9wqpIWz+hZlCnOk2u6dlLy6TyPVnix5s5PUfwiSMex85nRtor2UxXHwOcxiX/hfEeDeUtu+2PqbX4lXT+OPx94I3TOIgEuAAAAAElFTkSuQmCC);
  filter: invert(60%);
  -webkit-filter: invert(60%);
}

.translateZ {
  transform: translateZ(0);
}

html {
  background-color: rgb(243 244 246);
}

/* 세로 분리선 */
.divider-y {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  width: 100%;
  height: 1px;
  background-color: rgb(229 231 235);
}

/* Ant Design Mobile: Tabs */
.ant-m-tabs {
  --title-font-size: 1rem;
  font-weight: 600;
}
.ant-m-tabs.ant-m-tabs-no-padding {
  --content-padding: 0;
}
.ant-m-tabs .adm-tabs-header {
  background-color: rgb(255 255 255);
}
.ant-m-tabs.ant-m-tabs-page-sticky .adm-tabs-header {
  position: sticky;
  top: 3.5rem;
  z-index: 90;
}
.ant-m-tabs.ant-m-tabs-popup-sticky .adm-tabs-header {
  position: sticky;
  top: 3.5rem;
  z-index: 90;
}
.ant-m-tabs .ant-m-tabs-content {
  /*padding: 1rem;*/
  padding: 1rem 1rem 5rem 1rem;
  min-height: calc(100vh - 10rem);
}
.ant-m-tabs .ant-m-tabs-content-with-bottom-button {
  padding: 1rem 1rem 5rem 1rem;
  min-height: calc(100vh - 10rem);
}
.ant-m-tabs .adm-tabs-content {
  font-weight: 400;
}
/* Ant Design Mobile: Stepper */
.ant-m-stepper {
  --height: 2.5rem;
  --input-font-size: 1.125rem;
  --button-font-size: 1rem;
  --input-background-color: rgb(219 234 254);
}
.ant-m-stepper.ant-m-stepper-no-button .adm-button {
  display: none;
}
.ant-m-stepper.ant-m-stepper-no-button .adm-stepper-middle {
  border-left: 0;
  border-right: 0;
}
.ant-m-stepper.ant-m-stepper-no-button .adm-stepper-middle .adm-input {
  border-radius: 0.25rem !important;
}
.adm-stepper-middle .adm-input:focus-within {
  box-shadow: inset 0 0 0 1px rgb(0 0 0 / 0.3);
}
.ant-m-stepper.ant-m-stepper-bg-amber {
  --input-background-color: rgb(254 215 170) !important;
}
.ant-m-stepper.ant-m-stepper-bg-teal {
  --input-background-color: rgb(153 246 228) !important;
}

/* Ant Design Mobile: Input */
.ant-m-input {
  height: 2.5rem;
  --font-size: 1.125rem;
  --color: rgb(31 41 55);
  --placeholder-color: rgb(96 165 250);
  background-color: rgb(219 234 254);
  border-radius: 0.25rem !important;
  padding: 0 0.75rem;
}
.adm-input:focus-within {
  box-shadow: inset 0 0 0 1px rgb(0 0 0 / 0.3);
}

/* Ant Design Mobile: Textarea */
.ant-m-textarea {
  --color: rgb(31 41 55);
  --font-size: 1rem;
  --placeholder-color: rgb(96 165 250);
  background-color: rgb(219 234 254);
  border-radius: 0.25rem !important;
  padding: 0.5rem 0.75rem;
  font-weight: 400;
}
.adm-text-area:focus-within {
  box-shadow: inset 0 0 0 1px rgb(0 0 0 / 0.3);
}

/* Ant Design Mobile: Span */
.ant-m-span {
  font-size: 1.125rem;
  font-weight: 500;
  color: rgb(31 41 55);
}
.ant-m-span-content {
  font-size: 1rem;
  font-weight: 500;
  color: rgb(31 41 55);
}

/* Ant Design Mobile: Form.Item */
.adm-form-item-label {
  color: rgb(107 114 128) !important;
  font-weight: 400;
}
.adm-list-item-content-main {
  padding: 0 !important;
}

/* Ant Design Mobile: Popup */
.ant-m-popup {
  font-weight: 400;
}
.ant-m-popup.ant-m-popup-with-bottom-button {
  padding: 1rem 1rem 5rem 1rem;
}

/* Ant Design Mobile: Button */
.adm-button {
  border-radius: 0.25rem;
}

/* Ant Design Mobile: SwipeAction */
.ant-m-swipe-action button {
  margin-left: 1px;
}

/* Ant Design Mobile: Button */
.ant-m-button {
  height: 2.5rem;
}

.adm-calendar-picker-view-cell-top {
  font-size: 0.8rem !important;
  /*font-weight: 500;*/
}
